// Android 4.4.2 compatibility
import "core-js/features/map"
import "core-js/features/set"
import "core-js/features/promise"
import "core-js/features/object/set-prototype-of"
import "core-js/features/array/at"
import "core-js/features/array/find"
import "core-js/features/array/find-index"
import "core-js/features/object"

import Vue from "vue"
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue"

import App from "./App.vue"
import router from "./router"
import store from "./store"
import { sessionModule } from "./store/modules/session"
import NotificationHelper from "./helpers/NotificationHelper"
import TrackingHelper from "./helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "./helpers/tracking/Tracker"
import { syncModule } from "./store/modules/sync"
import "./filters"

declare global {
  interface Window {
    cordova?: {
      platformId: string
      getAppVersion: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getVersionNumber?: any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getVersionCode?: any
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      require: (name: string) => any
    }

    usingCordova?: boolean
  }
}

const APP_INSTALLED = "appInstalled"

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

const initTracking = () => {
  TrackingHelper.init()

  if (!localStorage.getItem(APP_INSTALLED)) {
    TrackingHelper.track(AppTrackEvent.appInstalled)
    localStorage.setItem(APP_INSTALLED, "true")
  }

  TrackingHelper.track(AppTrackEvent.appOpened)
}

const initStatusBar = () => {
  if (window.StatusBar && window.cordova && window.cordova.platformId === "android") {
    window.StatusBar.overlaysWebView(false)
    window.StatusBar.backgroundColorByHexString("#000000")
  }
}

const initServices = async () => {
  if (sessionModule.isAuthenticated) {
    await sessionModule.loadSessionData()
    await syncModule.startService()
  }
}

const vue = new Vue({
  router,
  store,
  async mounted() {
    if (window.usingCordova) {
      initStatusBar()

      NotificationHelper.initToken()
    }

    initTracking()

    await initServices()
  },
  render: (h) => h(App),
})

if (window.usingCordova) {
  document.addEventListener(
    "deviceready",
    () => {
      if (window.cordova) {
        let dsn: string | null = null
        if (window._env != null && window._env.SENTRY_DSN != null) {
          dsn = window._env.SENTRY_DSN
        } else if (process.env.SENTRY_DSN != null) {
          dsn = process.env.SENTRY_DSN
        }

        if (dsn != null) {
          const Sentry = window.cordova.require("sentry-cordova.Sentry")
          Sentry.init({ dsn })
        }
      }

      vue.$mount("#app")
    },
    false
  )
} else {
  vue.$mount("#app")
}

<template>
  <div class="home">
    <NavigationBar :title="title" />
    <div class="app-view">
      <div class="content d-flex justify-content-center flex-wrap flex-md-nowrap">
        <HomeModule />
        <div class="widget-container">
          <WidgetList />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import { sessionModule } from "@/store/modules/session"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"

@Component({
  components: {
    NavigationBar: async () => await import("@/components/navigation-bar/navigation-bar.vue"),
    HomeModule: async () => await import("@/components/home/home-module.vue"),
    WidgetList: async () => await import("@/components/widgets/widget-list.vue"),
  },
})
export default class Home extends Vue {
  get title() {
    return sessionModule.currentResidence?.name ?? "Résidence"
  }

  mounted() {
    TrackingHelper.track(AppTrackEvent.homeOpened)
  }
}
</script>

<style scoped lang="scss">
.home {
  height: 100%;
  display: flex;
  flex-flow: column;

  .content {
    height: 100%;
    padding: 0 25px;

    .widget-container {
      width: 100%;
      max-width: 600px;
    }
  }
}
</style>

<template>
  <div class="around-me">
    <NavigationBar :left-buttons="[{ text: 'Retour', back: true }]" title="Autour de moi" />

    <div class="app-view">
      <InternetError v-if="status === 'error'" :retry="loadData" />
      <div v-else-if="status !== 'ready'" class="loader"></div>
      <div v-else class="around-me-container d-flex">
        <div class="title">
          <div class="title-container d-flex justify-content-between align-items-center">
            <h1>Filtres</h1>
            <button v-b-toggle.collapse>
              <img src="@/assets/images/list.png" />
            </button>
          </div>
        </div>
        <div class="tab">
          <b-collapse id="collapse" v-model="visible">
            <h1 class="filter-title">Filtres</h1>
            <div class="filter-container d-flex flex-wrap flex-md-column flex-md-nowrap">
              <div class="filter-content d-flex align-items-center">
                <button @click="onCheckAll()">
                  <div class="checkbox">
                    <img
                      class="checked"
                      :class="{ 'd-none': !checkAll }"
                      src="@/assets/images/check/greenSea.png"
                      srcset="@/assets/images/check/greenSea@2x.png 2x, @/assets/images/check/greenSea@3x.png 3x"
                    />
                  </div>
                </button>
                <div class="filter-name">Tous</div>
              </div>
              <div v-for="(category, index) in categories" :key="category.id" class="filter-content d-flex align-items-center">
                <button @click="onCheckCategory(index)">
                  <div :class="'checkbox ' + category.color">
                    <img
                      class="checked"
                      :class="{ 'd-none': !category.checked }"
                      :src="require(`@/assets/images/check/${category.color}.png`)"
                      :srcset="
                        require(`@/assets/images/check/${category.color}@2x.png`) +
                        ' 2x,' +
                        require(`@/assets/images/check/${category.color}@3x.png`) +
                        ' 3x'
                      "
                    />
                  </div>
                </button>
                <div class="filter-name">{{ category.name }}</div>
              </div>
            </div>
          </b-collapse>
        </div>
        <div ref="map" class="flex-grow-1 content"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"
import FileHelper from "@/helpers/FileHelper"
import DeviceHelper from "@/helpers/DeviceHelper"
import { Platform } from "@/queries/auth/graphql"
import { PlaceFragment, PlaceCategoryFragment } from "@/queries/client/graphql"
import { sessionModule } from "@/store/modules/session"
import { Status } from "@/types/Status"
import APIClient from "@/api/ApiClient"
import WidgetPlace from "@/components/widgets/widget-place.vue"
import { MarkerType } from "@/types/Marker"

type Position = { lat: number; lng: number }

type Marker = {
  on: (eventName: string, callback: () => void) => void
  setVisible: (visible: boolean) => void
  getPosition: () => Position
}

type Map = {
  on: (eventName: string, callback: () => void) => void
  getDiv: () => Element
  addMarker: (data: { position: Position; icon: { url: string | ArrayBuffer | null } }) => Marker
  fromLatLngToPoint: (position: Position, callback: (point: number[]) => void) => void
}

type PlaceCategoryFilter = PlaceCategoryFragment & {
  checked: boolean
}

type PlaceWithMarker = PlaceFragment & {
  marker: Marker
}

type WidgetPlaceComponent = WidgetPlace & {
  setPlace: (place: PlaceFragment) => void
  setVisible: (visible: boolean) => void
  setPosition: (x: number, y: number) => void
}

@Component({
  components: {
    NavigationBar: async () => await import("@/components/navigation-bar/navigation-bar.vue"),
    InternetError: async () => await import("@/components/internet-error.vue"),
  },
})
export default class AroundMe extends Vue {
  checkAll = true
  visible = false
  residenceId: string | null = null
  places: PlaceFragment[] = []
  markerPlaces: PlaceWithMarker[] = []
  categories: PlaceCategoryFilter[] = []
  map: Map | null = null
  currentPlace: PlaceFragment | null = null
  widgetPlaceComponent: WidgetPlaceComponent | null = null
  status = Status.New

  $refs!: {
    map?: HTMLDivElement
  }

  created() {
    window.addEventListener("resize", this.onScreenChanged)
    window.addEventListener("orientationchange", this.onScreenChanged)
  }

  async beforeMount() {
    await this.loadData()
    this.setMap()
  }

  mounted() {
    TrackingHelper.track(AppTrackEvent.placesOpened)
    this.onScreenChanged()
  }

  destroyed() {
    window.removeEventListener("resize", this.onScreenChanged)
    window.removeEventListener("orientationchange", this.onScreenChanged)
  }

  async loadData() {
    const currentResidenceId = sessionModule.currentResidence?.id ?? null
    if (!currentResidenceId) {
      return
    }
    this.status = Status.Loading

    try {
      this.places = await APIClient.getPlaces(currentResidenceId)
      this.categories = (await APIClient.getPlaceCategories()).map((category) => ({ checked: true, ...category }))
      this.status = Status.Ready
    } catch (error) {
      this.status = Status.Error
    }
  }

  setMap() {
    if (this.$refs.map && window.plugin) {
      if (!DeviceHelper.isMobile()) {
        let apiKey: string | null = null

        if (window._env != null && window._env.GOOGLE_MAPS_BROWSER_API_KEY != null) {
          apiKey = window._env.GOOGLE_MAPS_BROWSER_API_KEY
        } else if (process.env.GOOGLE_MAPS_BROWSER_API_KEY != null) {
          apiKey = process.env.GOOGLE_MAPS_BROWSER_API_KEY
        }

        if (apiKey) {
          window.plugin.google.maps.environment.setEnv({
            API_KEY_FOR_BROWSER_RELEASE: apiKey,
            API_KEY_FOR_BROWSER_DEBUG: apiKey, // If key is empty or unset,
            // the maps plugin runs under the development mode.
          })
        }
      }

      const defaultPlace = this.places.find(({ category: { color } }) => color === "residence") ?? this.places[0]

      const map = window.plugin.google.maps.Map.getMap(this.$refs.map, {
        camera: {
          latLng: {
            lat: defaultPlace?.latitude ?? 48.866667,
            lng: defaultPlace?.longitude ?? 2.333333,
          },
          zoom: 15,
        },
      })

      // The MAP_READY event notifies the native map view is fully ready to use.
      map.one(window.plugin.google.maps.event.MAP_READY, this.onMapInit)
    }
  }

  onScreenChanged() {
    this.visible = window.innerWidth > 768
  }

  onMapInit(map: Map) {
    this.map = map

    map.on(window.plugin.google.maps.event.MAP_CLICK as string, this.hideInfo)
    map.on(window.plugin.google.maps.event.CAMERA_MOVE as string, this.positionChanged)
    map.on(window.plugin.google.maps.event.CAMERA_MOVE_END as string, this.positionChanged)
    map.on(window.plugin.google.maps.event.MAP_DRAG as string, this.positionChanged)
    map.on(window.plugin.google.maps.event.MAP_DRAG_END as string, this.positionChanged)

    this.widgetPlaceComponent = new WidgetPlace()
    this.widgetPlaceComponent.$mount()

    this.map.getDiv().appendChild(this.widgetPlaceComponent.$el)

    this.setMarkers()
  }

  setMarkers() {
    if (this.map && this.places) {
      for (let i = 0; i < this.places.length; i += 1) {
        this.setMarker(this.map, this.places[i])
      }
    }
  }

  onCheckCategory(index: number) {
    if (this.categories) {
      this.categories[index].checked = !this.categories[index].checked
      this.showMarker()
    }
  }

  onCheckAll() {
    this.checkAll = !this.checkAll

    this.categories = this.categories.map((_category) => {
      const category = { ..._category }
      category.checked = this.checkAll

      return category
    })
    this.showMarker()
  }

  showMarker() {
    this.markerPlaces = this.markerPlaces.map((markerPlace) => {
      const category = this.categories.find((value) => value.id === markerPlace.category.id && value.checked)
      markerPlace.marker.setVisible(!!category)

      if (markerPlace.id === this.currentPlace?.id && !category) {
        this.hideInfo()
      }

      return markerPlace
    })
  }

  async setMarker(map: Map, place: PlaceFragment) {
    let url: string | ArrayBuffer | null

    if (DeviceHelper.getPlatform() === Platform.Android) {
      url = await FileHelper.filepathToBase64(MarkerType[place.category.color])
    } else {
      url = window.location.origin + MarkerType[place.category.color]
    }

    const marker = map.addMarker({
      position: { lat: place.latitude, lng: place.longitude },
      icon: {
        url,
      },
    })

    marker.on(window.plugin.google.maps.event.MARKER_CLICK as string, () => this.showInfo(place))
    this.markerPlaces.push({ marker, ...place })
  }

  showInfo(place: PlaceFragment) {
    if (this.widgetPlaceComponent) {
      this.widgetPlaceComponent.setPlace(place)
      this.widgetPlaceComponent.setVisible(true)
    }

    TrackingHelper.track(AppTrackEvent.placesItemClicked, { placeId: place.id })

    this.currentPlace = place
    this.positionChanged()
  }

  hideInfo() {
    if (this.widgetPlaceComponent) {
      this.widgetPlaceComponent.setVisible(false)
    }
  }

  positionChanged() {
    const markerPlace = this.markerPlaces.find((place) => place.id === this.currentPlace?.id)

    if (markerPlace && this.map != null) {
      this.map.fromLatLngToPoint(markerPlace.marker.getPosition(), (point: number[]) => {
        if (this.widgetPlaceComponent) {
          this.widgetPlaceComponent.setPosition(point[0], point[1])
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.around-me {
  height: 100%;
  display: flex;
  flex-flow: column;

  .around-me-container {
    height: 100%;
    min-height: 0;
    overflow: hidden;

    .title {
      display: none;
      @include title-secondary-style();
    }

    .tab {
      width: 240px;
      height: 100%;
      background-color: white;
      overflow: auto;
      box-shadow: 0 5px 5px 5px rgba(rgb(31, 31, 31), 0.03);

      .filter-title {
        padding: 40px 40px 0 40px;
        @include title-secondary-style();
      }

      .filter-container {
        padding: 25px 35px 25px 40px;

        .filter-content {
          padding: 15px 0;

          .filter-name {
            @include body();
            padding-right: 15px;
          }

          .checkbox {
            width: 20px;
            height: 20px;
            margin-right: 8px;
            border-radius: 4px;
            border: solid 1.5px $secondary;
            background-color: rgba($background-default, 0.5);
            position: relative;

            &.aqua {
              border-color: $place-category-aqua;
            }

            &.pink {
              border-color: $place-category-pink;
            }

            &.cyan {
              border-color: $place-category-cyan;
            }

            &.blue {
              border-color: $place-category-blue;
            }

            &.orange {
              border-color: $place-category-orange;
            }

            &.red {
              border-color: $place-category-red;
            }

            &.blue {
              border-color: $place-category-blue;
            }

            &.magenta {
              border-color: $place-category-magenta;
            }

            &.residence {
              border-color: $primary;
            }

            &.skyBlue {
              border-color: $place-category-skyBlue;
            }

            &.yellowGreen {
              border-color: $place-category-yellowGreen;
            }

            &.forestGreen {
              border-color: $place-category-forestGreen;
            }

            &.gold {
              border-color: $place-category-gold;
            }

            &.deepPink {
              border-color: $place-category-deepPink;
            }

            &.darkMagenta {
              border-color: $place-category-darkMagenta;
            }

            &.darkOrange {
              border-color: $place-category-darkOrange;
            }

            &.darkRed {
              border-color: $place-category-darkRed;
            }

            .checked {
              position: absolute;
              bottom: 1px;
              left: 2px;
            }
          }
        }
      }
    }

    .content {
      min-width: 320px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @media (max-width: $md) {
      flex-flow: column;

      .title {
        width: 100%;
        height: 70px;
        text-align: left;
        display: inline-table;
        background-color: white;

        .title-container {
          height: 100%;
          padding: 0 30px;
        }
      }

      .tab {
        width: 100%;
        height: auto;

        .filter-title {
          display: none;
        }
      }
    }
  }
}
</style>
